<template>
  <div v-if="products && products.length > 0">
    <div class="heading-06 text-neutral-07 mb-3">Produse</div>

    <div class="d-none d-md-block">
      <div
        class="row align-items-center pb-3 mb-3 me-0 ms-0"
        v-for="(product, index) in products"
        :key="index"
        :class="{ 'border-bottom': index < products.length - 1 }"
      >
        <div class="col-auto ps-0">
          <router-link
            :to="{
              name: 'Product',
              params: {
                productName: $utils.StringToSlug(product.ProductName),
                productIdParam: product.ProductId,
              },
            }"
          >
            <img
              :src="product.ImgBase64"
              class="product-img"
              width="60px"
              alt="..."
              @error="$utils.ShowDefaultImage"
            />
          </router-link>
        </div>
        <div class="col ps-1">
          <div class="heading-08 text-neutral-07">
            {{ product.ProductName }}
          </div>
          <div class="row align-items-center mt-1 ms-0">
            <div
              class="col-auto pe-2 ps-0 mb-1"
              v-for="(configuration, idx) in product.Configurations"
              :key="idx"
            >
              <span class="badge config">{{ configuration.Name }}</span>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="heading-08 text-neutral-07">
            {{ $utils.DisplayProductPrice(product.Price) }} lei
          </div>
          <!-- <div class="text-button-02 text-primary-05 text-end">
            {{ product.FidelityPoints }} F.P.
          </div> -->
        </div>
        <div class="col-auto add-to-cart-section gray" style="width: 110px">
          <div class="input-group right h-100">
            <input
              type="number"
              class="form-control product-amount-input"
              v-model="product.Quantity"
              @keyup="this.$emit('update', product)"
            />
            <span class="input-group-text">
              <img
                src="../../../assets/images/icons/gray-chevron-up-20x20.svg"
                @click="ChangeQuantity(product, 1)"
                alt="..."
              />
              <img
                src="../../../assets/images/icons/gray-chevron-down-20x20.svg"
                :class="{ disabled: product.Quantity == 1 }"
                @click="product.Quantity > 1 ? ChangeQuantity(product, -1) : ''"
                alt="..."
              />
            </span>
          </div>
        </div>
        <div class="col-auto ps-0 pe-0">
          <button
            type="button"
            class="remove-product-btn"
            @click="this.$emit('remove', product.Id)"
          >
            <img src="../../../assets/images/icons/x-clear-icon-40x40.svg" alt="..." />
          </button>
        </div>
        <div
          class="col-12 ps-0 mt-3"
          v-if="product.HasChanged || product.IsRemoved || product.OutOfStock"
        >
          <div class="d-flex align-items-center" v-if="product.IsRemoved || product.OutOfStock">
            <img
              src="../../../assets/images/icons/triangle-danger-20x20.svg"
              class="me-2"
              alt="..."
            />
            <span v-if="product.IsRemoved" class="paragraph-04 fst-italic text-danger-05">
              Produsul selectat nu mai este pe stock.
            </span>
            <span v-if="product.OutOfStock" class="paragraph-04 fst-italic text-danger-05">
              Configurația selectată nu mai este disponibilă.
            </span>
          </div>
          <div class="d-flex align-items-center" v-else>
            <img
              src="../../../assets/images/icons/info-circle-info-20x20.svg"
              class="me-2"
              alt="..."
            />
            <span class="paragraph-04 fst-italic text-info-06">
              Prețul produsului a fost modificat.
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--mobile-->
    <div class="d-md-none">
      <div class="card product-mobile mb-4" v-for="(product, index) in products" :key="index">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <router-link
                :to="{
                  name: 'Product',
                  params: {
                    productName: $utils.StringToSlug(product.ProductName),
                    productIdParam: product.ProductId,
                  },
                }"
              >
                <img
                  :src="product.ImgBase64"
                  class="product-img"
                  alt="..."
                  @error="$utils.ShowDefaultImage"
                />
              </router-link>
            </div>
            <div class="col ps-1 heading-08 text-neutral-07">
              {{ product.ProductName }}
            </div>
          </div>
          <div class="row align-items-center mt-1">
            <div class="col-auto" v-for="(configuration, idx) in product.Configurations" :key="idx">
              <span class="badge config">{{ configuration.Name }}</span>
            </div>
          </div>
          <hr class="text-blacktint-01" />
          <div class="row align-items-center justify-content-between">
            <div class="col-auto">
              <div class="heading-08 text-neutral-07">
                {{ $utils.DisplayProductPrice(product.Price) }} lei
              </div>
            </div>
            <div class="col-auto">
              <div class="row amount-buttons align-items-center">
                <div class="col">
                  <button
                    type="button"
                    class="button"
                    :disabled="product.Quantity == 1"
                    @click="product.Quantity > 1 ? ChangeQuantity(product, -1) : ''"
                  >
                    <img src="../../../assets/images/icons/minus-gray-16x16.svg" alt="..." />
                  </button>
                </div>
                <div class="col">
                  <div class="text-button-02 text-neutral-05">
                    {{ product.Quantity }}
                  </div>
                </div>
                <div class="col">
                  <button type="button" class="button" @click="ChangeQuantity(product, 1)">
                    <img src="../../../assets/images/icons/plus-gray-16x16.svg" alt="..." />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-between mt-2">
            <div
              class="col-auto text-button-01 text-danger-05"
              @click="this.$emit('remove', product.Id)"
            >
              Șterge
            </div>
          </div>
          <hr class="text-blacktint-01" v-if="product.HasChanged || product.IsRemoved" />
          <div
            class="col-12 ps-0 mt-3"
            v-if="product.HasChanged || product.IsRemoved || product.OutOfStock"
          >
            <div class="d-flex align-items-center" v-if="product.IsRemoved || product.OutOfStock">
              <img
                src="../../../assets/images/icons/triangle-danger-20x20.svg"
                class="me-2"
                alt="..."
              />
              <span v-if="product.IsRemoved" class="paragraph-04 fst-italic text-danger-05">
                Produsul selectat nu mai este pe stock.
              </span>
              <span v-if="product.OutOfStock" class="paragraph-04 fst-italic text-danger-05">
                Configurația selectată nu mai este disponibilă.
              </span>
            </div>
            <div class="d-flex align-items-center" v-else>
              <img
                src="../../../assets/images/icons/info-circle-info-20x20.svg"
                class="me-2"
                alt="..."
              />
              <span class="paragraph-04 fst-italic text-info-06">
                Prețul produsului a fost modificat.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="products && products.length === 0">
    <div class="sub-heading-03 text-neutral-05 mb-3 text-center">
      Nu ai niciun produs adăugat în coșul de cumpărături.
    </div>
    <div class="text-center paragraph-02 text-neutral-08 mb-3 mt-5 pt-5">
      Nu știi de unde să începi? <br />
      Alege una din categoriile de mai jos:
    </div>
    <div class="row">
      <div class="col-sm-4 mb-3 mb-sm-0" v-for="(categ, index) in productCategories" :key="index">
        <router-link
          :to="{
            name: 'Products',
            params: {
              categoryName: $utils.StringToSlug(categ.Name),
              categoryId: categ.Id,
            },
          }"
          class="product-category"
        >
          <div class="card">
            <div class="card-body">
              <!-- <img :src="GetAttachmentName(categ.Img)" width="50" alt="..." class="icon" /> -->
              <img :src="GetAttachmentName(categ.Img)" width="50" alt="..." class="pt-3" />
              <div class="title text-secondary-05 mt-3">
                {{ categ.Name }}
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5967 5.93005L20.6667 12.0001L14.5967 18.0701"
                    stroke="#95691D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.6665 12H20.4965"
                    stroke="#95691D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="text-center mt-5 pt-5">
      <router-link
        :to="{ name: 'Products' }"
        class="button btn-medium-wider btn-primary-solid h-100"
        target="_blank"
      >
        Alege din produsele noastre
        <img src="../../../assets/images/icons/arrow-right-20x20.svg" alt="..." />
      </router-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CartProducts',
  emits: ['update', 'remove', 'logIn'],
  props: {
    products: Array,
    cartAvailable: Boolean,
  },
  data() {
    return {
      productCategories: [],
    };
  },
  methods: {
    GetAttachmentName(imgName) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/attachments/categories/${imgName}.svg`);
    },
    ChangeQuantity(product, quantity) {
      // eslint-disable-next-line no-param-reassign
      if (product.Quantity + quantity >= 0) {
        // eslint-disable-next-line no-param-reassign
        product.Quantity += quantity;
      }
      this.$emit('update', product);
    },
    GetCategories() {
      this.$store.state.loader = true;
      this.$axios
        .get('/Category/getAll')
        .then((response) => {
          this.productCategories =
            response.data && response.data.length > 3 ? response.data.slice(0, 3) : response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
  },
  computed: {
    someProductsChangedProperties() {
      const atLeastOneChangedProduct = this.products.some(
        // eslint-disable-next-line comma-dangle
        (prod) => prod.HasChanged
      );
      return atLeastOneChangedProduct;
    },
    someProductsHaveNegativeQuantity() {
      return this.products.some((prod) => prod.Quantity <= 0);
    },
  },
  watch: {
    products: {
      immediate: true,
      handler(value) {
        if (value && value.length == 0) this.GetCategories();
      },
    },
  },
};
</script>

<style scoped>
.product-category:hover .card {
  background-color: var(--secondary-1);
}

.product-category .card .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.product-category .card .card-body .icon {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

@media only screen and (min-width: 1200px) {
  .product-category .card .title {
    font-size: 24px;
  }
  .product-category .card .card-body .icon {
    width: 80px;
    height: 80px;
  }
}

.product-category .card {
  border: 1px solid var(--secondary-3);
  height: 100%;
  transition: 0.2s;
}
.product-category .card .card-body {
  padding: 12px 16px;
  text-align: center;
}

.amount-buttons .button {
  background-color: #f0f2f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.product-mobile.card {
  border: 1px solid transparent;
  box-shadow: 0px 3px 11px 0px #00000026;
}
.product-mobile.card .card-body {
  padding: 8px;
}

button.remove-product-btn:hover img {
  filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(0%) contrast(104%);
}
button.remove-product-btn:hover {
  border: 1px solid var(--neutral-3);
}
button.remove-product-btn {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  transition: 0.2s;
}

.badge.config {
  padding: 4px 6px;
  color: var(--neutral-5);
  border: 1px solid var(--neutral-5);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.product-img {
  width: 62.5px;
  height: 62.5px;
  border-radius: 4px;
  object-fit: cover;
}
.border-bottom {
  border-color: var(--neutral-3) !important;
}
</style>
