<!-- eslint-disable indent -->
<template>
  <div class="overflow-hidden">
    <div class="container-md" style="margin-top: 40px; margin-bottom: 80px">
      <div class="row">
        <div class="col-lg-7 pe-lg-0 mb-5 mb-lg-0">
          <div class="row">
            <div class="col">
              <div class="heading-03 text-secondary-05">Coșul de cumpărături</div>
              <div class="sub-heading-03 text-neutral-06 mt-2 pt-1">
                Bine ați venit în Coșul de cumpărături al universului nostru dulce! Aici, visele
                dvs. culinare prind viață, iar fiecare clic reprezintă un pas mai aproape de
                momentul perfect al răsfățului cu deliciile noastre.
              </div>
            </div>
            <div class="col-auto d-none d-md-block d-lg-none">
              <img
                src="../../assets/images/cart-top-right-cookie.webp"
                class="top-right-cookie-img"
                alt="..."
              />
            </div>
          </div>
          <div class="cart card">
            <div class="card-body">
              <div class="d-none d-sm-block">
                <div class="row align-items-center heading-08 text-neutral-04">
                  <div
                    class="col-auto me-1"
                    :class="{ 'text-neutral-07': selectedTab === 'Details' }"
                  >
                    Detalii
                  </div>
                  <div class="col-auto">
                    <img
                      src="../../assets/images/icons/chevron-right-20x20-gray_accentuate.svg"
                      v-if="selectedTab === 'ConfirmOrder'"
                    />
                    <img src="../../assets/images/icons/chevron-right-20x20-gray.svg" v-else />
                  </div>
                  <div
                    class="col-auto"
                    :class="{ 'text-neutral-07': selectedTab === 'ConfirmOrder' }"
                  >
                    Plată
                  </div>
                </div>
                <hr class="text-neutral-07" style="margin-top: 12px; margin-bottom: 24px" />
              </div>
              <CartDetailsWithProductsComponent
                v-if="selectedTab === 'Details'"
                @remove="RemoveProduct"
                @update="UpdateQuantity"
                :products="cart.Products"
                @change-tab="ChangeTab"
                @getState="GetStatesSearch"
                @getCity="GetCitiesSearch"
                :user="user"
                :deliveryLocation="deliveryLocation"
                :location="location"
                :states="states"
                :cities="cities"
                :settings="dateSettings"
                @logIn="logIn"
                :selectedTab="selectedTab"
              />
              <CartConfimOrderComponent
                v-if="selectedTab === 'ConfirmOrder'"
                :finalPrice="Number(cart.FinalPrice)"
                :products="cart.Products"
                :deliveryLocation="deliveryLocation"
                :user="user"
                :location="location"
                :payment="payment"
                :cartId="cart.Id"
                @changeTab="ChangeTab"
                @remove="RemoveProduct"
                @update="UpdateQuantity"
                @create="CreateOrder"
              />
            </div>
          </div>
        </div>
        <div class="col position-relative ps-0">
          <img
            src="../../assets/images/cart-top-right-cookie.webp"
            class="top-right-cookie-img d-none d-lg-block"
            alt="..."
          />
          <div class="cart-costs-section">
            <div class="row align-items-center text-neutral-07 mt-3 pb-3 mb-3">
              <div class="col heading-08">Subtotal</div>
              <div class="col-auto heading-06">
                {{ $utils.DisplayProductPrice(cart.TotalPrice) || 0 }} lei
              </div>
            </div>
            <div class="border-bottom-golden pb-3 mb-3"></div>
            <div class="heading-08 text-neutral-06 mb-2 pb-1">Ai cupon de reducere?</div>
            <div class="input-group left">
              <span class="input-group-text" id="coupon">
                <img
                  src="../../assets/images/icons/gray-gift-linear-24x24.svg"
                  class="pe-3"
                  alt="..."
                />
                <svg
                  width="1"
                  height="24"
                  viewBox="0 0 1 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="1" height="24" fill="#95A6AC" />
                </svg>
              </span>
              <input
                v-model="couponName"
                type="text"
                class="form-control"
                placeholder="Cod de reducere"
                aria-label="Coupon"
                aria-describedby="coupon"
              />
              <div class="coupon-button">
                <button
                  type="button"
                  class="button btn-primary-solid pt-1 pb-1 ps-2 pe-2"
                  @click="currentUser ? GetCoupon() : GetCouponOffline()"
                >
                  Adaugă
                </button>
              </div>
            </div>
            <div v-if="coupon" class="row align-items-center mt-3">
              <div class="col heading-08 text-neutral-06">Reducere cupon</div>
              <div class="col-auto heading-06 text-primary-05">
                <p v-if="coupon.Type === true">-{{ coupon.Value }} %</p>
                <p v-if="coupon.Type === false">-{{ coupon.Value }} lei</p>
              </div>
            </div>
            <div v-if="couponNotValid" class="heading-09 text-danger-05 mt-3">
              Acest cupon nu este valid!
            </div>
            <div class="border-bottom-golden pb-3 mb-4"></div>
            <div class="row text-neutral-07">
              <div class="col heading-07 fw-semibold">Total</div>
              <div class="col-auto heading-04">
                {{
                  $utils.DisplayProductPrice(
                    Number(
                      this.cart.FinalPrice - this.fidelityPoints * this.fidelityPointsValue || 0
                    )
                  ) || 0
                }}
                lei
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';
import CartProductsComponent from '../../components/Cart/CartProductsComponent.vue';
import CartDetailsWithProductsComponent from '../../components/Cart/CartDetailsWithProductsComponent.vue';
import CartConfimOrderComponent from '../../components/Cart/CartConfimOrderComponent.vue';

export default {
  name: 'CartView',
  data() {
    return {
      cart: [],
      totalPrice: 0,
      couponName: '',
      coupon: null,
      couponNotValid: false,
      user: {
        ClientType: {
          Name: 'Persoana fizică',
          IsJuridicalPerson: false,
        },
        Delivery: 'Pick-Up'
      },
      fidelityPoints: 0,
      fidelityPointsInput: '',
      fidelityPointsValue: 0,
      selectedTab: '',
      payment: {
        Method: 'In magazin',
      },
      deliveryLocation: {
        Country: 'Romania'
      },
      cities: [],
      cityFilter: {
        PageSize: 42,
        PageNumber: 1,
        SearchQuery: '',
      },
      cityPagination: {},
      states: [],
      stateFilter: {
        PageSize: 42,
        PageNumber: 1,
        SearchQuery: '',
      },
      statePagination: {},
      location: {},
      dateSettings: {
        MinPickUpFieldDate: 0,
        PickUpFieldDate: null,
      },
    };
  },
  components: {
    CartProductsComponent,
    CartDetailsWithProductsComponent,
    CartConfimOrderComponent,
    Field,
    ErrorMessage,
    Form,
  },
  methods: {
    CalculateFinalPrice() {
      if (this.coupon && this.cart.TotalPrice) {
        if (this.coupon.Type) {
          const finalPrice =
            this.cart.TotalPrice - (this.cart.TotalPrice * this.coupon.Value) / 100;
          return finalPrice;
        }
        const finalPrice = this.cart.TotalPrice - this.coupon.Value;
        return finalPrice;
      }
      if (this.cart.TotalPrice) {
        return this.cart.FinalPrice;
      }
      return 0;
    },
    logIn(closeModal) {
      if (closeModal) {
        $(`#${closeModal}`).modal('hide');
      }
      $('#logInModal').modal('show');
    },
    FidelityPointsSubmit() {
      this.fidelityPoints = JSON.parse(JSON.stringify(this.fidelityPointsInput));
    },
    CreateOrder() {
      this.$store.state.loader = true;
      const order = {
        IsJuridicalPerson: this.user.ClientType.IsJuridicalPerson,
        ...(this.user.CompanyCIF ? { CompanyCIF: this.user.CompanyCIF } : ''),
        ...(this.user.CompanyName ? { CompanyName: this.user.CompanyName } : ''),
        ...(this.user.CompanyTradeNumber
          ? { CompanyTradeNumber: this.user.CompanyTradeNumber }
          : ''),
        Payment: this.payment.Method,
        ClientSecret: this.payment.ClientSecret,
        UserName: this.user.UserName,
        PhoneNumber: this.user.PhoneNumber,
        Email: this.user.Email,
        Delivery: this.user.Delivery,
        ...(this.coupon != null ? { CouponId: this.coupon.Id } : ''),
        Address: this.deliveryLocation.Address,
        ...(this.deliveryLocation.Address2 ? { Address2: this.deliveryLocation.Address2 } : ''),
        Country: this.deliveryLocation.Country,
        State: this.deliveryLocation.State.Name
          ? this.deliveryLocation.State.Name
          : this.deliveryLocation.State,
        City: this.deliveryLocation.City.Name
          ? this.deliveryLocation.City.Name
          : this.deliveryLocation.City,
        ...(this.deliveryLocation.PostalCode
          ? { PostalCode: this.deliveryLocation.PostalCode }
          : ''),
        ...(this.deliveryLocation.Clarifications
          ? { Clarifications: this.deliveryLocation.Clarifications }
          : ''),
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
        PickUpDay:
          this.dateSettings.PickUpFieldDate === true
            ? this.$moment(this.user.PickUpDay).format('YYYY-MM-DD')
            : null,
        ...(this.$localStorageMethods.GetCartId()
          ? { CartId: this.$localStorageMethods.GetCartId() }
          : ''),
      };
      this.$axios
        .post(`/Order/${this.$utils.ApiNameByCurrentUser('create')}`, order)
        .then((response) => {
          this.$localStorageMethods.SetCartId('');
          window.dispatchEvent(new Event('CartChangedEventBackend'));
          this.$store.state.loader = false;
          this.$router.push({
            name: 'ThanksForOrder',
            params: {
              orderId: response.data,
              pickUpDate: this.$moment(this.user.PickUpDay).format('DD-MM-YYYY'),
            },
          });
        })
        .catch(() => {
          this.$localStorageMethods.SetCartId('');
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    ChangeTab(tab) {
      this.selectedTab = tab;
      this.payment.Method = 'In magazin';
      setTimeout(() => {
        // this is for Safari
        window.scroll({ top: -1, left: 0, behavior: 'smooth' });
      }, 10);
    },
    async GetCartProducts() {
      const cartId = this.$localStorageMethods.GetCartId();
      this.$store.state.loader = true;
      const searchParams = {
        ...(this.coupon ? { CouponId: this.coupon.Id } : ''),
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
        ...(cartId ? { CartId: cartId } : ''),
      };
      this.$axios
        .get(`/Order/${this.$utils.ApiNameByCurrentUser('getCart')}?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          window.dispatchEvent(new Event('CartChangedEventBackend'));
          this.cart = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetCoupon() {
      this.$store.state.loader = true;
      const searchParams = {
        Name: this.couponName,
        Price: this.cart.TotalPrice,
      };
      this.$axios
        .get(`/Coupon/getCoupon?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.coupon = response.data;
          this.couponNotValid = false;
          this.GetCartProducts();
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.couponNotValid = true;
          this.$store.state.loader = false;
          // this.$swal.fire('Avertisment', 'Acest cupon nu este valabil.', 'warning');
        });
    },
    GetCouponOffline() {
      this.$store.state.loader = true;
      const searchParams = {
        Name: this.couponName,
        Price: this.cart.TotalPrice,
      };
      this.$axios
        .get(`/Coupon/getCoupon?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.coupon = response.data;
          if (this.coupon.Type === true) {
            this.cart.FinalPrice =
              this.cart.TotalPrice - (this.cart.TotalPrice * this.coupon.Value) / 100;
          } else if (this.cart.TotalPrice - this.coupon.Value > 0) {
            this.cart.FinalPrice = this.cart.TotalPrice - this.coupon.Value;
          }
          this.$store.state.loader = false;
          this.couponNotValid = false;
        })
        .catch(() => {
          this.couponNotValid = true;
          this.$store.state.loader = false;
        });
    },
    UpdateQuantity(product) {
      if (product.Quantity > 0) {
        this.$store.state.loader = true;
        const searchParams = {
          Id: product.Id,
          Quantity: product.Quantity,
        };
        this.$axios
          .post(`/Order/updateQuantity?${new URLSearchParams(searchParams)}`)
          .then(() => {
            this.GetCartProducts();
            this.$store.state.loader = false;
          })
          .catch(() => {
            this.$store.state.loader = false;
            this.$utils.ToastNotify('warning', 'Va rugăm să introduceți o cantitate validă.');
          });
      }
    },
    RemoveProduct(id) {
      this.$swal
        .fire({
          title: 'Avertisment',
          text: 'Sigur doriți să ștergeți produsul din coș?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#dd2531',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Da',
          cancelButtonText: 'Renunță',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.state.loader = true;
            this.$axios
              .delete(`/Order/removeProduct/${id}`)
              .then(() => {
                this.GetCartProducts();
                this.$store.state.loader = false;
              })
              .catch(() => {
                this.$store.state.loader = false;
                this.$utils.ToastNotify('error', 'Something went wrong.');
              });
          }
        });
    },

    GetUserDetails() {
      if (!this.currentUser) return;
      this.$store.state.loader = true;
      this.$axios
        .get('/User/getDetailsCart')
        .then((response) => {
          this.user = response.data;
          this.user.ClientType = {
            Name: 'Persoana fizică',
            IsJuridicalPerson: false,
          };
          this.user.Delivery = 'Pick-Up';
          this.deliveryLocation = response.data.Location != null ? response.data.Location : {};
          this.GetStates(this.deliveryLocation.State);
          this.GetCities(this.deliveryLocation.City);
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetLocation() {
      this.$store.state.loader = true;
      this.$axios
        .get(`/Location/get/${this.$store.state.locationId}`)
        .then((response) => {
          this.location = response.data;
          this.selectedTab = 'ConfirmOrder';
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetStatesSearch(searchQuery) {
      this.$store.state.loader = true;
      const searchParams = {
        PageNumber: this.stateFilter.PageNumber,
        PageSize: this.stateFilter.PageSize,
        ...(searchQuery ? { SearchQuery: searchQuery } : ''),
      };
      this.$axios
        .get(`/County/getCounties?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.states = response.data.Items;
          this.statePagination = response.data.PageDetails;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetCitiesSearch(searchQuery) {
      this.$store.state.loader = true;
      const searchParams = {
        PageNumber: this.cityFilter.PageNumber,
        PageSize: this.cityFilter.PageSize,
        ...(searchQuery ? { SearchQuery: searchQuery } : ''),
        ...(this.deliveryLocation.State && this.deliveryLocation.State.Id
          ? { CountyId: this.deliveryLocation.State.Id }
          : ''),
      };
      this.$axios
        .get(`/County/getCities?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.cities = response.data.Items;
          this.cityPagination = response.data.PageDetails;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetStates(searchQuery) {
      this.$store.state.loader = true;
      const searchParams = {
        PageNumber: this.stateFilter.PageNumber,
        PageSize: this.stateFilter.PageSize,
        ...(searchQuery ? { SearchQuery: searchQuery } : ''),
      };
      this.$axios
        .get(`/County/getCounties?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.states = response.data.Items;
          this.statePagination = response.data.PageDetails;
          if (searchQuery && response.data.Items.length === 1) {
            this.deliveryLocation.State = this.states[0];
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetCities(searchQuery) {
      this.$store.state.loader = true;
      const searchParams = {
        PageNumber: this.cityFilter.PageNumber,
        PageSize: this.cityFilter.PageSize,
        ...(searchQuery ? { SearchQuery: searchQuery } : ''),
        ...(this.deliveryLocation.State && this.deliveryLocation.State.Id
          ? { CountyId: this.deliveryLocation.State.Id }
          : ''),
      };
      this.$axios
        .get(`/County/getCities?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.cities = response.data.Items;
          this.cityPagination = response.data.PageDetails;
          if (searchQuery && response.data.Items.length === 1) {
            this.deliveryLocation.City = this.cities[0];
            if (!this.deliveryLocation.PostalCode) {
              this.deliveryLocation.PostalCode = this.cities[0].ZipCode;
            }
          }
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetFidelitPointsValue() {
      this.$store.state.loader = true;
      this.$axios
        .get('/Settings/getFidelityPointsValue')
        .then((response) => {
          this.fidelityPointsValue = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },

    FinalPriceCalculator() {
      this.cart.FinalPrice = this.totalPrice;
    },
    GetPickUpDaySettings() {
      this.$axios
        .get('/Settings/getPickUpDaySettings')
        .then((response) => {
          this.dateSettings = response.data;
        })
        .catch(() => {
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    schema() {
      return yup.object({
        fidelityPoints: yup
          .number()
          .transform((value) => (!value ? undefined : value))
          .nullable()
          .test(
            'fidelity-points-validation',
            'Nu aveti suficiente puncte de fidelitate',
            (value) => {
              if (value > this.user.FidelityPoints) {
                return false;
              }

              return true;
              // eslint-disable-next-line comma-dangle
            }
          )
          .test(
            'fidelity-points-validation',
            'Vă  rugăm să folosiți mai puține puncte de fidelizare',
            (value) => {
              if (this.cart.FinalPrice - value * this.fidelityPointsValue < 0) {
                return false;
              }
              return true;
              // eslint-disable-next-line comma-dangle
            }
          ),
      });
    },
  },
  mounted() {
    this.GetCartProducts();
    this.GetUserDetails();
    this.GetFidelitPointsValue();
    this.GetPickUpDaySettings();
    this.ChangeTab('Details');
  },
};
</script>

<style scoped>
@media only screen and (max-width: 576px) {
  .heading-03 {
    font-size: 28px;
    line-height: 1;
  }

  .sub-heading-03 {
    font-size: 15px;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .heading-03 {
    font-size: 5vw;
    line-height: 1;
  }

  .sub-heading-03 {
    line-height: 1.3;
  }
}

.input-group.left .input-group-text {
  border-color: var(--neutral-4) !important;
}

.input-group.left .form-control {
  border-color: var(--neutral-4) !important;
}

.input-group.left input.form-control {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: var(--neutral-8) !important;
}

.border-bottom-golden {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-image: linear-gradient(
    90deg,
    rgba(223, 210, 187, 0) 0%,
    rgba(223, 210, 187, 0.6) 9.37%,
    #bfa577 48.44%,
    rgba(223, 210, 187, 0.6) 90.1%,
    rgba(223, 210, 187, 0) 100%
  );
  border-image-slice: 1;
}

.coupon-button {
  position: absolute;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 5;
  right: 0;
}

@media only screen and (max-width: 992px) {
  .top-right-cookie-img {
    max-width: 22vh;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .top-right-cookie-img {
    max-width: 55vh;
  }
}

.cart-costs-section {
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-image: linear-gradient(
    99.35deg,
    rgba(191, 165, 119, 0.7) 0%,
    rgba(223, 210, 187, 0.7) 8.31%,
    rgba(149, 105, 29, 0.7) 14.58%,
    rgba(223, 210, 187, 0.7) 40.1%,
    rgba(191, 165, 119, 0.7) 66.84%,
    rgba(244, 240, 232, 0.7) 89.12%,
    rgba(149, 105, 29, 0.7) 100%
  );
  border-image-slice: 1;
  padding: 24px 24px 24px 25px;
}

@media only screen and (min-width: 992px) {
  .top-right-cookie-img {
    top: -125px;
    left: -25px;
    position: absolute;
    z-index: -1;
  }

  .cart-costs-section {
    margin-top: 390px;
  }
}

.cart.card {
  border-radius: 12px;
  border-color: transparent;
  margin-top: 40px;
}

.cart.card .card-body {
  padding: 5px;
}

@media only screen and (min-width: 768px) {
  .cart.card {
    box-shadow: 0px 4px 10px 0px #00000026;
  }

  .cart.card .card-body {
    padding: 24px;
  }
}

@media (min-width: 1400px) {
  .container-md {
    max-width: 1245px;
  }
}
.container-md{
  min-height: 75vh;
}
</style>
