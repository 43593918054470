<template>
  <div>
    <div class="mb-5 d-sm-none">
      <div class="row align-items-center heading-08 text-neutral-04 justify-content-between">
        <div class="col-auto"></div>
        <div
          class="col-auto"
          @click="
            products.length === 0 ||
            cartAvailable ||
            someProductsHaveNegativeQuantity ||
            someProductsNotAvailable
              ? ''
              : Submit()
          "
        >
          Plată
          <img src="../../assets/images/icons/chevron-right-20x20-gray.svg" />
        </div>
      </div>
      <hr class="text-blacktint-01" style="margin-top: 12px; margin-bottom: 12px" />
    </div>
    <cart-products-component
      :products="products"
      :cartAvailable="cartAvailable"
      @remove="$emit('remove', $event)"
      @update="$emit('update', $event)"
      @logIn="$emit('logIn')"
    />
    <div v-if="products && products.length !== 0">
      <cart-details-component
        @getState="$emit('getState', $event)"
        @getCity="$emit('getCity', $event)"
        @logIn="$emit('logIn')"
        :user="user"
        :deliveryLocation="deliveryLocation"
        :location="location"
        :states="states"
        :cities="cities"
        :settings="settings"
        ref="cartDetailsComponentRef"
      />
      <div
        class="row align-items-center mt-md-5 pt-3 pt-sm-5 justify-content-between justify-content-md-start"
      >
        <div class="col"></div>
        <div class="col-auto mt-3 mt-sm-0">
          <img
            src="../../assets/images/icons/rounded-question.svg"
            class="me-3"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Nu se poate trece la pasul următor dacă există produse în coș care au prețul sau configurația schimbată."
            v-if="cartAvailable"
            alt="..."
          />
          <img
            src="../../assets/images/icons/rounded-question.svg"
            alt="..."
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Unul dintre produsele alese are prețul modificat sau nu mai este valabil"
            class="me-3"
            v-if="someProductsChangedProperties || someProductsNotAvailable"
          />
          <button
            @click="
              products.length === 0 ||
              cartAvailable ||
              someProductsHaveNegativeQuantity ||
              someProductsNotAvailable
                ? ''
                : Submit()
            "
            type="button"
            class="button btn-medium-wider"
            :class="[
              products.length === 0 ||
              cartAvailable ||
              someProductsHaveNegativeQuantity ||
              someProductsNotAvailable
                ? 'btn-disabled-solid'
                : 'btn-primary-solid',
            ]"
            :disabled="
              products.CannotContinue ||
              someProductsHaveNegativeQuantity ||
              someProductsNotAvailable
            "
          >
            Continuă cu plata
            <img src="../../assets/images/icons/arrow-right-20x20.svg" alt="..." />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartDetailsComponent from './Short/CartDetailsComponent.vue';
import CartProductsComponent from './Short/CartProductsComponent.vue';

export default {
  name: 'CartDetailsWithProductsComponent ',
  emits: ['remove', 'update', 'getCity', 'getState', 'logIn', 'changeTab'],
  data() {
    return {};
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    cartAvailable: Boolean,
    // details
    user: Object,
    location: Object,
    states: Array,
    cities: Array,
    deliveryLocation: Object,
    settings: Object,
    selectedTab: String,
  },
  components: { CartProductsComponent, CartDetailsComponent },
  methods: {
    async Submit() {
      const validation =
        await this.$refs.cartDetailsComponentRef.$refs.cartDetailsFormRef.validate();
      if (!validation.valid) {
        const list = document.querySelectorAll('.invalid-input');
        if (list && list.length > 0) {
          list[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      } else this.$emit('changeTab', 'ConfirmOrder');
    },
  },
  computed: {
    someProductsChangedProperties() {
      const atLeastOneChangedProduct = this.products.some(
        // eslint-disable-next-line comma-dangle
        (prod) => prod.HasChanged
      );
      return atLeastOneChangedProduct;
    },
    someProductsHaveNegativeQuantity() {
      return this.products.some((prod) => prod.Quantity <= 0);
    },
    someProductsNotAvailable() {
      const atLeastOneChangedProduct = this.products.some(
        // eslint-disable-next-line comma-dangle
        (prod) => prod.OutOfStock || prod.IsRemoved
      );
      return atLeastOneChangedProduct;
    },
  },
};
</script>
<style scoped></style>
