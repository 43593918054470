<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="mb-5 d-sm-none">
    <div class="row align-items-center heading-08 text-neutral-04 justify-content-between">
      <div class="col-auto" @click="$emit('changeTab', 'Details')">
        <img src="../../assets/images/icons/chevron-right-20x20-gray.svg" class="rotate-180degrees" />
        Detalii
      </div>
      <div class="col-auto"></div>
    </div>
    <hr class="text-blacktint-01" style="margin-top: 12px; margin-bottom: 12px" />
    <div class="text-center heading-06 text-neutral-07">Plată</div>
  </div>
  <div class="row border-bottom-golden pb-4 mb-4">
    <div class="col-12 mb-3">
      <div class="heading-08 text-neutral-07 mb-1">Destinatar</div>
      <div class="row align-items-center paragraph-02 text-neutral-06 justify-content-between justify-content-sm-start">
        <div class="col-auto">
          {{ user.UserName }}
        </div>
        <div class="col-auto d-none d-sm-block">
          <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3" cy="3" r="3" fill="#95A6AC" />
          </svg>
        </div>
        <div class="col-auto">
          {{ user.PhoneNumber }}
        </div>
      </div>
    </div>
    <div class="col">
      <div v-if="user.Delivery === 'Delivery'">
        <div class="heading-08 text-neutral-07 mb-1">Livrare la adresă</div>
        <div class="paragraph-02 text-neutral-06">
          {{ user.Location.Address }},
          <span v-if="user.Location.City && user.Location.City.Name">
            {{ user.Location.City.Name }}
          </span>
        </div>
        <div v-if="user.Location.State && user.Location.State.Name" class="paragraph-02 text-neutral-06">
          {{ user.Location.State.Name }}, {{ user.Location.Country }}
        </div>
      </div>

      <div v-else>
        <div class="heading-08 text-neutral-07">Ridicare din magazin</div>
        <div class="paragraph-02 text-neutral-06">
          {{ location.Name }}
        </div>
        <div class="paragraph-04">
          {{ location.Description }}
        </div>
      </div>
    </div>
  </div>
  <div class="row border-bottom-golden pb-4 mb-4">
    <div class="heading-08 text-neutral-07 mb-2">Plata</div>
    <div class="row aling-items-center d-flex justify-content-center pb-2">
      <div class="col-md mb-3 mb-md-0">
        <!--eslint-disable-next-line vue/no-mutating-props-->
        <input class="form-check-input me-2" type="radio" name="radio" id="repay" @click="payment.Method = 'In magazin'"
          :checked="payment.Method === 'In magazin'" />
        <label class="text-button-01 text-neutral-05 pointer" for="repay"> La ridicare </label>
      </div>
      <div class="col">
        <input class="form-check-input me-2" type="radio" name="radio" id="card" @click="InitStripePayment()"
          :checked="payment.Method === 'Card'" />
        <label class="text-button-01 text-neutral-05 pointer" for="card"> Online, cu cardul </label>
      </div>
    </div>

    <div v-if="payment.Method == 'Card'">
      <hr />
      <form id="payment-form">
        <div id="payment-element"></div>
      </form>
    </div>
  </div>
  <div class="d-none d-md-block">
    <div class="row align-items-center pb-3 mb-3 me-0 ms-0" v-for="(product, index) in products" :key="index"
      :class="{ 'border-bottom': index < products.length - 1 }">
      <div class="col-auto ps-0">
        <router-link :to="{
          name: 'Product',
          params: {
            productName: $utils.StringToSlug(product.ProductName),
            productIdParam: product.ProductId,
          },
        }">
          <img :src="product.ImgBase64" class="product-img" alt="..." @error="$utils.ShowDefaultImage" />
        </router-link>
      </div>
      <div class="col ps-1">
        <div class="heading-08 text-neutral-07">
          {{ product.ProductName }}
        </div>
        <div class="row align-items-center mt-1 ms-0">
          <div class="col-auto pe-2 ps-0 mb-1" v-for="(configuration, idx) in product.Configurations" :key="idx">
            <span class="badge config">{{ configuration.Name }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="col-auto add-to-cart-section gray" style="width: 110px">
        <div class="input-group right h-100">
          <input
            type="number"
            class="form-control product-amount-input"
            v-model="product.Quantity"
            @keyup="this.$emit('update', product)"
          />
          <span class="input-group-text">
            <img
              src="../../assets/images/icons/gray-chevron-up-20x20.svg"
              @click="ChangeQuantity(product, 1)"
              alt="..."
            />
            <img
              src="../../assets/images/icons/gray-chevron-down-20x20.svg"
              :class="{ disabled: product.Quantity == 1 }"
              @click="product.Quantity > 1 ? ChangeQuantity(product, -1) : ''"
              alt="..."
            />
          </span>
        </div>
      </div> -->
      <!-- <div class="col-auto ps-0 pe-0">
        <button
          type="button"
          class="remove-product-btn"
          @click="this.$emit('remove', product.Id)"
          alt="..."
        >
          <img src="../../assets/images/icons/x-clear-icon-40x40.svg" alt="..." />
        </button>
      </div> -->
      <div class="col-auto pe-s-0 pe-0">
        <div class="row align-items-center">
          <div class="col-auto heading-08 text-neutral-07 d-flex">
            <img src="../../assets/images/icons/cupcake_20x20.svg" />
            <div class="ms-2" style="min-width: 35px">x {{ product.Quantity }}</div>
          </div>
          <div class="col-1"></div>
          <div class="col-auto heading-08 text-neutral-07">
            <img src="../../assets/images/icons/tag_20x20.svg" />
            {{ $utils.DisplayProductPrice(product.Price) }} lei
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--mobile products-->
  <!--mobile-->
  <div class="d-md-none">
    <div class="card product-mobile mb-4" v-for="(product, index) in products" :key="index">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <router-link :to="{
              name: 'Product',
              params: {
                productName: $utils.StringToSlug(product.ProductName),
                productIdParam: product.ProductId,
              },
            }">
              <img :src="product.ImgBase64" @error="$utils.ShowDefaultImage" class="product-img" alt="..." />
            </router-link>
          </div>
          <div class="col ps-1 heading-08 text-neutral-07">
            {{ product.ProductName }}
          </div>
        </div>
        <div class="row align-items-center mt-1">
          <div class="col-auto" v-for="(configuration, idx) in product.Configurations" :key="idx">
            <span class="badge config">{{ configuration.Name }}</span>
          </div>
        </div>
        <hr class="text-blacktint-01" />
        <div class="row align-items-center justify-content-between">
          <div class="col">
            <div class="heading-08 text-neutral-07 d-flex">
              <img src="../../assets/images/icons/cupcake_20x20.svg" />
              <div class="ms-2" style="min-width: 35px">x {{ product.Quantity }}</div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row amount-buttons align-items-center">
              <!-- <div class="col">
                <button
                  type="button"
                  class="button"
                  :disabled="product.Quantity == 1"
                  @click="product.Quantity > 1 ? ChangeQuantity(product, -1) : ''"
                >
                  <img src="../../assets/images/icons/minus-gray-16x16.svg" alt="..." />
                </button>
              </div> -->
              <div class="col">
                <!-- <div class="text-button-02 text-neutral-05">
                  {{ product.Quantity }}
                </div> -->
                <div class="row align-items-center">
                  <div class="col-auto heading-08 text-neutral-07">
                    <img src="../../assets/images/icons/tag_20x20.svg" />
                    {{ $utils.DisplayProductPrice(product.Price) }} lei
                  </div>
                </div>
              </div>
              <!-- <div class="col">
                <button type="button" class="button" @click="ChangeQuantity(product, 1)">
                  <img src="../../assets/images/icons/plus-gray-16x16.svg" alt="..." />
                </button>
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="row align-items-center justify-content-between mt-2">
           <div class="col-auto">
            <div class="text-button-02 text-primary-05">{{ product.FidelityPoints }} F.P.</div>
          </div>
          <div
            class="col-auto text-button-01 text-danger-05"
            @click="this.$emit('remove', product.Id)"
          >
            Șterge
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!--end mobile products-->
  <div class="row align-items-center mt-5 pt-5 justify-content-between justify-content-md-start">
    <div class="col">
      <button type="button" class="button btn-medium btn-neutral-outlined" @click="$emit('changeTab', 'Details')">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.97435 5.44165L2.91602 10.5L7.97435 15.5583" stroke="#688088" stroke-width="1.5"
            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M17.0836 10.5H3.05859" stroke="#688088" stroke-width="1.5" stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Înapoi
      </button>
    </div>
    <div class="col-auto">
      <button type="button" class="button btn-medium-wider" @click="!someProductsHaveNegativeQuantity && CreateOrder()"
        :class="[someProductsHaveNegativeQuantity ? 'btn-disabled-solid' : 'btn-primary-solid']"
        :disabled="someProductsHaveNegativeQuantity">
        Plasează comanda

      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
let elements;
export default {
  name: 'CartConfirmOrder',
  emits: ['update', 'remove', 'changeTab', 'create'],
  props: {
    products: Array,
    selectedTab: String,
    user: Object,
    payment: Object,
    location: Object,
    finalPrice: Number,
    deliveryLocation: Object,
    cartId: Number,
  },
  data() {
    return {
      stripe: null,
      elements: null,
      errorMessage: '',
      settings: {},
    };
  },
  watch: {
    products: {
      handler(value) {
        if (value && value.length == 0) this.$emit('changeTab', 'Products');
      },
      deep: true,
    },
  },
  computed: {
    someProductsHaveNegativeQuantity() {
      return this.products.some((prod) => prod.Quantity <= 0);
    },
  },
  methods: {
    async InitStripePayment() {
      this.$store.state.loader = true;
      this.payment.Method = 'Card';
      await this.$axios
        .get('/Settings/getPaymentSettings')
        .then((response) => {
          this.settings = response.data;
        })
        .catch(() => {
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });

      const input = {
        Amount: this.finalPrice,
        Customer: this.user.UserName,
        CartId: this.cartId
      };
      console.log(input);

      await this.$axios
        .post('/Stripe/initOrder', input)
        .then((response) => {
          if (!this.stripe) {
            this.stripe = Stripe(
              this.settings.StripeToken //striper
            );
          }
          const clientSecret = response.data.clientSecret;
          this.payment.ClientSecret = response.data.clientSecret;
          const appearance = {
            theme: 'stripe',
          };
          elements = this.stripe.elements({ appearance, clientSecret });

          const paymentElementOptions = {
            layout: 'tabs',
          };

          const paymentElement = elements.create('payment', paymentElementOptions);
          paymentElement.mount('#payment-element');
          console.log(paymentElement)
          this.$store.state.loader = false;
        })
        .catch((error) => {
          this.AddStripeErrorToOrder(JSON.stringify(error.message));
          this.$utils.ToastNotify('error', 'Something went wrong.');
          this.$store.state.loader = false;
        });
    },

    async CreateOrder() {
      if (this.payment.Method == 'Card') {
        await this.PayWithCard();
      } else {
        this.$emit('create');
      }
    },

    async EmitSmartBill() {
      debugger;
      // https://api.smartbill.ro/live-doc/#post-/invoice
      var invoice = {
        //Delicitate details
        companyVatCode: this.settings.CompanyVatCode,
        seriesName: 'GRA',
        //
        client: {
          name: this.user.UserName,
          address: this.deliveryLocation.Address,
          city: this.deliveryLocation.City.Name,
          county: this.deliveryLocation.State.Name,
          country: this.deliveryLocation.Country,
          email: this.user.Email,
          phone: this.user.PhoneNumber,
          saveToDb: true,
        },
        issueDate: new Date(),
        isDraft: false,
        currency: 'ron',
        language: 'ROMANA',
        products: [],
        payment: {
          value: this.finalPrice,
          type: 'Card',
          isCash: false,
        },
      };

      this.products.forEach(function (product) {
        invoice.products.push({
          name: product.ProductName,
          code:
            product.Configurations.length > 0
              ? product.ProductName.trim().replace(/\s/g, '-') +
              '-' +
              product.Configurations.map((x) => x.Name).join('-')
              : product.ProductName.trim().replace(/\s/g, '-'),
          measuringUnitName: 'buc',
          currency: 'RON',
          quantity: product.Quantity,
          price: product.Price / product.Quantity,
          isTaxIncluded: true,
          taxPercentage: !!product.Tva ? product.Tva : 0,
          saveToDb: true,
        });
      });

      if (this.user.ClientType.IsJuridicalPerson) {
        invoice.client.vatCode = this.user.CompanyCIF;
        invoice.client.regCom = this.user.CompanyTradeNumber;
        invoice.client.contact = this.user.UserName;
        invoice.client.name = this.user.CompanyName;
      }

      await this.$axios
        .post('https://ws.smartbill.ro/SBORO/api/invoice', invoice, {
          headers: {
            Authorization: `Basic ${this.settings.SmartbillToken}`, //smartBill
          },
        })
        .then(async (response) => { })
        .catch((error) => {
          this.AddStripeErrorToOrder(JSON.stringify(error));
        });
    },

    async PayWithCard() {
      this.$store.state.loader = true;
      const { error } = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'http://192.168.1.7:8080/',
          receipt_email: this.user.Email,
        },
        redirect: 'if_required',
      });

      if (error) {
        this.$utils.ToastNotify('error', error.message);
        this.AddStripeErrorToOrder(JSON.stringify(error));
        this.$store.state.loader = false;
      }
      else {
        await this.EmitSmartBill();
        this.$emit('create');
      }
    },

    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case 'succeeded':
          this.showMessage('Payment succeeded!');
          break;
        case 'processing':
          this.showMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          this.showMessage('Your payment was not successful, please try again.');
          break;
        default:
          this.showMessage('Something went wrong.');
          break;
      }
      this.errorMessage = '';
    },

    showMessage: function (messageText) {
      this.errorMessage = messageText;
    },

    ChangeQuantity(product, quantity) {
      // eslint-disable-next-line no-param-reassign
      if (product.Quantity + quantity > 0) {
        // eslint-disable-next-line no-param-reassign
        product.Quantity += quantity;
      }
      this.$emit('update', product);
    },

    AddStripeErrorToOrder(message) {
      const value = {
        Message: message,
        CartId: this.cartId,
        TotalPrice: this.finalPrice,
        Email: this.user.Email,
        PhoneNumber: this.user.PhoneNumber,
      };
      this.$axios
        .post('/Order/addStripeErrorToOrder', value)
        .then((response) => { })
        .catch((error) => { });
    },
    async GetSettings() {

    }
  },
  mounted() {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://js.stripe.com/v3/');
    document.head.appendChild(recaptchaScript);
  },
};
</script>

<style scoped>
.quantity-box {
  background-color: var(--neutral-2);
  padding: 8px 14px;
  width: 62px;
  border-radius: 8px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.5px;
  text-align: center;
  color: var(--neutral-7);
}

@media (min-width: 576px) {
  .quantity-box {
    padding: 10px 16px;
    width: 62px;
    font-size: 18px;
    text-align: center;
  }
}

.amount-buttons .button {
  background-color: #f0f2f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.product-mobile.card {
  border: 1px solid transparent;
  box-shadow: 0px 3px 11px 0px #00000026;
}

.product-mobile.card .card-body {
  padding: 8px;
}

button.remove-product-btn:hover img {
  filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(0%) contrast(104%);
}

button.remove-product-btn:hover {
  border: 1px solid var(--neutral-3);
}

button.remove-product-btn {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  transition: 0.2s;
}

.badge.config {
  padding: 4px 6px;
  color: var(--neutral-5);
  border: 1px solid var(--neutral-5);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.product-img {
  width: 62.5px;
  height: 62.5px;
  border-radius: 4px;
  object-fit: cover;
}

.border-bottom-golden {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-image: linear-gradient(90deg,
      rgba(223, 210, 187, 0) 0%,
      rgba(223, 210, 187, 0.6) 9.37%,
      #bfa577 48.44%,
      rgba(223, 210, 187, 0.6) 90.1%,
      rgba(223, 210, 187, 0) 100%);
  border-image-slice: 1;
}
</style>
